.desc-box {
  display: flex;
  flex-wrap: wrap;
  padding: 1px;

  .desc-item {
    width: max-content;
    padding: 10px 0;
    margin-right: 25px;
    font-size: 14px;

    .label {
      color: #00000073;
    }

    .children {
      color: #000000E0;
      display: inline-block;
      min-width: 4em;
      margin-left: 10px;
    }
  }
}
@primary-color: #2ac2bd;