.custom-upload {
  .ant-upload {
    display: none;
  }
}

.upload-circle {
  .ant-upload-list-item {
    border-radius: 50%;
  }

  .ant-upload-select-picture-card {
    border-radius: 50%;
  }

  .ant-upload-list-item-image {
    border-radius: 50%;
  }
}

.left-card {
  height: 100%;
}
@primary-color: #2ac2bd;