#container{
  padding: 0;
  margin: 0;
  width: 100%;
  height: 600px;

  .amap-logo {
    display: none !important;
    visibility: hidden !important;
  }

  .amap-copyright {
    opacity: 0 !important;
  }
}

.map-img {
  width: 20px;
  height: 30px;
}
@primary-color: #2ac2bd;