.info-root {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &.single-row{
    display: flex;
    flex-direction: column;
    .info-item{
      width: 100%;
    }
  }

  .info-item {
    width: 33%;
    margin-bottom: 12px;
    .info-title {
      font-size: 12px;
      font-weight: bold;
      color: #9b9b9b;
    }

    .info-value {

    }
  }
}

@primary-color: #2ac2bd;