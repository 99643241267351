/* ==================
          按钮
 ==================== */
.cu-btn-scan,
.cu-btn-scan:hover,
.cu-btn-scan:focus {
  background: #1dc5a3 !important;
  color: #fff !important;
  border-color: #1dc5a3 !important;
  margin-right: 10px !important;
}
.cu-btn-gray,
.cu-btn-gray:hover,
.cu-btn-gray:focus {
  background: gray !important;
  color: #fff !important;
  border-color: gray !important;
  margin-right: 10px !important;
}
.cu-btn-red,
.cu-btn-red:hover,
.cu-btn-red:focus {
  background: red !important;
  color: #fff !important;
  border-color: red !important;
  margin-right: 10px !important;
}
.cu-btn-green,
.cu-btn-green:hover,
.cu-btn-green:focus {
  background: #1ab394 !important;
  color: #fff !important;
  border-color: #1ab394 !important;
  margin-right: 10px !important;
}
.cu-btn-blue,
.cu-btn-blue:hover,
.cu-btn-blue:focus {
  background: #1890ff !important;
  color: #fff !important;
  border-color: #1890ff !important;
  margin-right: 10px !important;
}
.view-btn,
.view-btn:hover,
.view-btn:focus {
  background-color: #e5f2ff;
  color: #2f82ff;
  border: 1px solid #2f82ff;
}
.edit-btn,
.edit-btn:hover,
.edit-btn:focus {
  background-color: #cbf3f1;
  color: #33cec9;
  border: 1px solid #33cec9;
}
.delete-btn,
.delete-btn:hover,
.delete-btn:focus {
  background-color: #feecec;
  color: #f36d6d;
  border: 1px solid #f36d6d;
}
.ant-tabs .ant-tabs-nav {
  margin-bottom: 0;
}
.flex1 {
  flex: 1;
}
.flex2 {
  flex: 2;
}
.hide {
  display: none;
}
.show {
  display: flex;
}
.d-line-block {
  display: inline-block;
}
.d-none {
  display: none;
}
.d-line {
  display: inline;
}
.d-flex {
  display: flex;
}
.df-column {
  flex-direction: column;
}
.df-fs0 {
  flex-shrink: 0;
}
.df-fx1 {
  flex: 1;
}
.df-aic {
  align-items: center;
}
.df-jcc {
  justify-content: center;
}
.df-jcsb {
  justify-content: space-between;
}
.df-jcsa {
  justify-content: space-around;
}
.df-wrap {
  flex-wrap: wrap;
}
.mt16 {
  margin-top: 16px;
}
.text-align-center {
  text-align: center;
}
.text-align-right {
  text-align: right;
}
.text-align-left {
  text-align: left;
}
.width-100-percentage {
  width: 100%;
}
.height-100-percentage {
  height: 100%;
}
.margin-none {
  margin: 0;
}
.margin-right-xs {
  margin-right: 10px !important;
}
.margin-right-sm {
  margin-right: 20px !important;
}
.margin-left-xs {
  margin-left: 10px !important;
}
.margin-left-sm {
  margin-left: 20px !important;
}
.margin-top-xss {
  margin-top: 5px !important;
}
.margin-top-xs {
  margin-top: 10px !important;
}
.margin-top-sm {
  margin-top: 20px !important;
}
.margin-bottom-xs {
  margin-bottom: 10px !important;
}
.margin-bottom-sm {
  margin-bottom: 20px !important;
}
.padding-none {
  padding: 0;
}
.padding-xs {
  padding: 10px !important;
}
.padding-right-xs {
  padding-right: 10px !important;
}
.padding-right-sm {
  padding-right: 20px !important;
}
.padding-left-xs {
  padding-left: 10px !important;
}
.padding-left-sm {
  padding-left: 20px !important;
}
.padding-top-xs {
  padding-top: 10px !important;
}
.padding-top-sm {
  padding-top: 20px !important;
}
.padding-bottom-xs {
  padding-bottom: 10px !important;
}
.padding-bottom-sm {
  padding-bottom: 20px !important;
}
.icon-fontsize {
  font-size: 16px !important;
}
.break-all {
  word-break: break-all;
}
.white-space-pre-wrap {
  white-space: pre-wrap;
}
.c-pointer {
  cursor: pointer;
}
.c-move {
  cursor: move;
}
th.ant-table-cell {
  text-align: center !important;
}
.ant-table-body {
  overflow-y: auto !important;
  scrollbar-color: #7d7d7d transparent;
  scrollbar-width: thin;
}
.ant-table-body::-webkit-scrollbar {
  width: 8px;
  height: 10px;
  background: transparent;
}
.ant-table-body::-webkit-scrollbar-thumb {
  width: 8px;
  height: 10px;
  border-radius: 4px;
  background: #7d7d7d;
}
tr.ant-table-row td.ant-table-cell {
  height: 50px;
  padding: 5px 5px 4px !important;
}
@media (max-width: 1360px) and (max-height: 768px) {
  tr.ant-table-row td.ant-table-cell {
    height: 36px;
    padding: 5px 5px 4px !important;
  }
}
.ant-drawer-content-wrapper .ant-drawer-header {
  height: 46px;
  box-sizing: border-box;
}
.ant-drawer-content-wrapper .ant-drawer-body {
  position: relative;
}
.ant-drawer-content-wrapper .ant-drawer-body form#basic {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px 0;
  bottom: 53px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: #7d7d7d transparent;
  scrollbar-width: thin;
}
.ant-drawer-content-wrapper .ant-drawer-body form#basic::-webkit-scrollbar {
  background: transparent;
  width: 8px;
}
.ant-drawer-content-wrapper .ant-drawer-body form#basic::-webkit-scrollbar-thumb {
  background: #7d7d7d;
  width: 8px;
  border-radius: 4px;
}
.ant-drawer-content-wrapper .ant-drawer-body form#basic .ant-form-item {
  margin-bottom: 0 !important;
}
.ant-drawer-content-wrapper .drawer-bottom {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 46px;
  border-top: 1px solid #e9e9e9;
  padding: 7px 16px;
  box-sizing: border-box;
  background: #fff;
  text-align: right;
  z-index: 1;
}
.ant-modal-content form#basic {
  padding: 10px 0;
  bottom: 53px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: #7d7d7d transparent;
  scrollbar-width: thin;
}
.ant-modal-content form#basic::-webkit-scrollbar {
  background: transparent;
  width: 8px;
}
.ant-modal-content form#basic::-webkit-scrollbar-thumb {
  background: #7d7d7d;
  width: 8px;
  border-radius: 4px;
}
.ant-modal-content form#basic .ant-form-item {
  margin-bottom: 0 !important;
}
.ant-modal-wrap {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: #fff transparent;
  scrollbar-width: thin;
}
.ant-modal-wrap::-webkit-scrollbar {
  background: transparent;
  width: 8px;
}
.ant-modal-wrap::-webkit-scrollbar-thumb {
  background: #fff;
  width: 8px;
  border-radius: 4px;
}
.ant-modal-wrap .ant-modal {
  padding: 0;
}
.ant-modal-wrap .modal-pos-ab-bottom {
  position: absolute;
  top: unset !important;
  display: block !important;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.ant-modal-wrap .modal-pos-ab-bottom .ant-pagination {
  margin: 8px 0 0 !important;
}
.ant-modal-wrap .modal-pos-ab-bottom .ant-modal-footer {
  margin-top: 0;
}
