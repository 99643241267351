.box {
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;

  .ant-typography {
    word-break: break-all;
  }
}
@primary-color: #2ac2bd;