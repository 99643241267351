.header-action {
  margin-bottom: 24px;
  text-align: right;
}

.document-card {
  margin-bottom: 16px;
  height: 280px;
  position: relative;

  .plus-out-lined {
    color: #ccc;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 300px;
  }

  .ant-card-cover {
    margin: 0;
  }

  .ant-card-actions > li {
    margin: 0;
  }

  .title {
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
  }

  .meta-bottom {
    position: absolute;
    bottom: 50px;
    width: 100%;

    .ant-card-meta-detail {
      width: 100%;
    }

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.pagination-container {
  text-align: center;
}

@primary-color: #2ac2bd;