.site-layout-center {
  flex: 1;
  height: 100%;
}

.site-layout-center-row {
  height: 100%;
}

.site-layout-center-col {
  height: 100%;
  display: flex;
  align-items: center;
}

.select-background-color {
  .ant-select-selector {
    border: 1px solid #1890ff  !important;
    background-color: rgba(224, 241, 255, .9) !important;
  }
}

@primary-color: #2ac2bd;