.user-details-container {
  .top-container {
  }

  .content {
    flex: 1;
    overflow-y: auto;
  }
}

@primary-color: #2ac2bd;