.company {
  width: 100%;
  height: 100%;
}

.ant-drawer {
  .ant-drawer-body {
    padding: 24px 24px 48px;
  }
}

.divider-padding {
  margin: 0 0 24px;
}
@primary-color: #2ac2bd;