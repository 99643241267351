.work-time-calendar {
  flex: 1;
  position: relative;

  .day-cell {
    padding-top: 32px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 8px;
    padding-right: 8px;
  }

  .record {
    border-radius: 2px;
    font-size: 10px;
    font-weight: bold;
    padding: 0 2px;
    color: white;
    margin-bottom: 2px;
    margin-right: 4px;

    &.not-work {
      background-color: red;
    }

    &.work {
      background-color: green;
    }

    &.no-record {
      background-color: #cccccc;
    }
  }
}
@primary-color: #2ac2bd;