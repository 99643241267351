.login-container {
  width: 100%;
  height: 100%;
  position: relative;

  .left-container {
    height: 100vh;
    background-image: url("~@assets/images/login-bg.jpg");
    background-size: 100% 100%;
  }

  .right-container {
    height: 100vh;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .login-content {
    width: 100%;

    .login-title {
      text-align: center;
      letter-spacing: 0.2em;
      font-size: 30px;
      color: rgba(0, 0, 0, 0.8);
      font-weight: bold;
    }

    .system-title {
      text-align: center;
      color: #1890ff;
      font-size: 30px;
      margin-top: 10px;
      letter-spacing: 0.1em;
      margin-bottom: 25px;
    }

    .login-button {
      margin-top: 20px;
      width: 100%;
    }
  }

  .footer-container {
    position: absolute;
    bottom: 5%;
    width: 100%;
    color: rgba(0, 0, 0, 0.6);

    .version-info {
      text-align: center;
      margin-bottom: 10px;
    }

    .company-info {
      text-align: center;
    }
  }

  .image-style {
    margin-top: 30px;
    margin-left: 60px;
  }
}

@primary-color: #2ac2bd;