.site-layout-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 12px;

  .user-name {
    color: #ffffff;
  }

  .site-layout-button {
    color: #ffffff;
    font-size: 24px;
    margin-right: 20px;
  }
}

@primary-color: #2ac2bd;