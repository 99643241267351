.ant-layout-sider {
  background-color: #f5f5f5 !important;
  position: fixed !important;
  top: 0;
  left: 0;
  bottom: 0;
  transition: all .3s !important;
}

.scroll-view{
  position: absolute;
  top: 46px;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: auto;
  overflow-x: hidden;
  width: 140px;
  scrollbar-color: #f5f5f5 transparent;
  scrollbar-width: thin;

  .ant-menu-item-selected{
    background: linear-gradient(rgb(230, 247, 255),rgb(220, 237, 255)) !important;
    color: rgb(24, 144, 255) !important;
  }

  &.collapsed{
    width: 80px;
    .menu{
      width: 80px;
    }
  }

  .menu{
    border: none !important;
    width: 140px;
  }

  &::-webkit-scrollbar{
    width: 8px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb{
    border-radius: 4px;
    width: 8px;
    background-color: #7d7d7d;
  }
}

.ant-menu {
  background-color: #f5f5f5 !important;
}

.logo {
  height: 46px;
  width: 140px;
  background-color: #1890ff;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  font-weight: bold;
  white-space: nowrap;
  transition: all .3s !important;
  overflow: hidden;

  .title{
    transition: all .3s;
    text-align: center;
    width: 140px;
  }

  .image-margin {
    margin-top: 11%;
  }

  &.collapsed{
    width: 80px;
    .title{
      width: 0;
      overflow: hidden;
    }
  }

}

.logo-title-large {
  font-size: 16px;
}

.logo-title-middle {
  font-size: 14px;
}

.logo-title-small {
  font-size: 12px;
}

.menu-icon {
  font-size: 18px !important;
}

.site-layout {
  background-color: #ffffff;
  position: fixed !important;
  width: calc(100% - 140px) !important;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  transition: all .3s !important;
  left: 140px;

  &.collapsed{
    left: 80px;
    width: calc(100% - 80px) !important;
  }

  .site-layout-header {
    padding: 0;
    height: 46px;
    background-color: #1890ff;
    //background-image: linear-gradient(#0b88fc, #1788f1);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    .site-layout-left {
      display: flex;
      padding-right: 12px;

      .site-layout-icon {
        color: #ffffff;
        margin-left: 16px;
      }

      .site-layout-title {
        color: #ffffff;
        text-indent: 1rem;
      }
    }
  }

  .site-layout-content {
    position: absolute;
    top: 46px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden auto;
    padding: 0 10px;
    scrollbar-color: #7d7d7d transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar{
      width: 8px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb{
      width: 8px;
      border-radius: 4px;
      background: #7d7d7d;
    }
  }

  .site-layout-no-tabs {
    margin-top: 10px;
  }

}

@primary-color: #2ac2bd;