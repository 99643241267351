.custom-upload {
  .ant-upload {
    display: none;
  }
}

.step-container {
  .checked-icon {
    margin-left: 12px;
    color: #5fbfbc
  }

  .step-body {
    .step-buttons {
      .print-btn {
        margin-right: 16px;
      }

      .select-width {
        width: 200px;
      }

      .submit-btn {

      }
    }
  }

}

.hire-container {
  flex: 1;
  overflow-y: auto;

  .salary-item {
    margin-bottom: 12px;

    .select {
      width: 160px;
      margin-right: 16px;
    }

    .input {
      width: 120px;
      margin-right: 16px;
    }

  }
}


@primary-color: #2ac2bd;