.qrcode-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .qrcode-content {
    padding: 20px;
    border: 1px solid rgb(236, 236, 236);
  }

  .qrcode-action {
    width: 100%;
    text-align: center;
    margin-top: 30px;

    .cancel-button {
      border-radius: 5px;
      padding: 0 30px;
      color: #ffffff;
      border: 1px solid rgb(190, 191, 191);
      background-color: rgb(190, 191, 191);
    }

    .download-button {
      margin-left: 20px;
      border-radius: 5px;
      padding: 0 30px;
    }
  }
}
@primary-color: #2ac2bd;