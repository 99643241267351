.on-board-container{
  .top-container{
    .top-filter-container{
      display: flex;
      flex-direction: row;
    }
  }
  .table-container{
    flex:1
  }
}

@primary-color: #2ac2bd;