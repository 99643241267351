.ant-popover-inner {
  padding: 4px !important;
}

.content-box {
  .ant-btn{
    justify-content: flex-start;
  }
}

@primary-color: #2ac2bd;