.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .icon-rotate {
    transform: rotate(-180deg);
  }
}

@primary-color: #2ac2bd;