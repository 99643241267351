.divider-margin {
  margin: 8px 0;
}

.col-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}


.btn-edit{
  padding: 0 !important;
  color: #ffec3d;
}

.btn-plus {
  padding: 0 !important;
  color: #73d13d;
}

.btn-close {
  padding: 0 !important;
  color: #ff4d4f;
}

.btn-view {
  padding: 0 !important;
  color: #4096ff;
}

@primary-color: #2ac2bd;