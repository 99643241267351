.date-center {
  text-align: center;

  .date-style {
    width: 50%;

    .ant-picker-input input {
      font-size: 20px;
      line-height: 20px;
      font-weight: bold;
      text-align: center;
    }

    .ant-picker-suffix {
      display: none;
    }
  }
}

.button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}
@primary-color: #2ac2bd;