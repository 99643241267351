.calendar-container {


  .top-tool-bar {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;

    .date-style {
      .ant-picker-input input {
        font-size: 20px;
        line-height: 20px;
        font-weight: bold;
        text-align: center;
      }

      .ant-picker-suffix {
        display: none;
      }
    }
  }

  .calendar-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .cell-container {
      width: calc(100% / 7);
      margin-bottom: 8px;

      &.week-label {
        margin-top: 16px;
        margin-bottom: 32px;
        font-weight: bold;
        text-align: center;
      }

      &.cell-1, &.cell-2, &.cell-3, &.cell-4, &.cell-5, &.cell-6 {
        padding-left: 8px;
      }

      .cell-content {
        height: 72px;
        position: relative;
        background-color: white;
        border-radius: 6px;
        overflow: hidden;
        border: 1px solid #cccccc;

        .not-blank {
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;


          .day {
            font-size: 18px;
            font-weight: bold;
            position: absolute;
            right: 8px;
            top: 4px;
          }
        }
      }
    }
  }
}

@primary-color: #2ac2bd;