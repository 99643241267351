.second, .third {
  .ant-table{
    border-radius: 0;
  }
  .ant-table-cell {
    padding: 0 !important;
  }

  .ant-table-container {
    border: none !important;
  }

  > .ant-table-cell {
    border: none !important;
  }
}

.print-box{

}
@primary-color: #2ac2bd;