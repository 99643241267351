.ant-table-row-expand-icon {
  float: none !important;
  transform: none !important;
  margin: 0 !important;
}

.ts-level {
  transform: translateX(0em);
}

.indent1 {
  .ts-level {
    transform: translateX(1em);
  }
}

.indent2 {
  .ts-level {
    transform: translateX(2em);
  }
}
@primary-color: #2ac2bd;