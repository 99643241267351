.tabs-content {
  position: absolute;
  top: 38px;
  bottom: 0;
  left: 0;
  right: 0;
  height: calc(100% - 38px);
  padding: 5px 10px;
}

.icon-close {
  margin-left: 5px;
}

.ant-tabs-tab {
  margin: 6px 6px 6px 0 !important;
  padding: 2px 6px !important;
  border-radius: 8px !important;
  border: none !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-shadow: 0 0 4px 1px rgba(222, 222, 222, .2) !important;
  transition: all .2s ease;

  &:hover {
    box-shadow: 0 0 4px 1px rgba(222, 222, 222, .4) inset !important;
  }

  .ant-tabs-tab-remove {
    margin-left: 0 !important;

    &:hover {
      color: brown !important;
    }
  }

  &.ant-tabs-tab-active {
    background: rgb(230, 247, 255) !important;

    .ant-tabs-tab-btn {
      color: rgb(24, 144, 255) !important;
    }

    .ant-tabs-tab-remove {
      color: rgb(24, 144, 255) !important;
      //margin-left: 4px !important;

      &:hover {
        color: brown !important;
      }
    }
  }

}

@primary-color: #2ac2bd;