.work-notice {

  .scroll-box {
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-color: #7d7d7d transparent;
    scrollbar-width: thin;
    height: 260px;

    &::-webkit-scrollbar {
      background: transparent;
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #7d7d7d;
      width: 8px;
      border-radius: 4px;
    }

    .scroll-content {
      width: 100%;
      height: max-content;

      .item {
        border-bottom: 1px dotted #d4d4d4;
        padding: 6px 0;
        color: #333;
        user-select: none;

        &:hover {
          border-bottom-color: #1890ff;
          cursor: pointer;
          color: #1890ff;
        }
      }
    }
  }
}
@primary-color: #2ac2bd;