@font-face {
  font-family: "management"; /* Project id 3501175 */
  src: url('iconfont.woff2?t=1708313313617') format('woff2'),
       url('iconfont.woff?t=1708313313617') format('woff'),
       url('iconfont.ttf?t=1708313313617') format('truetype');
}

.management {
  font-family: "management" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hr-jiantou_xiangyouliangci:before {
  content: "\eb09";
}

.hr-hetong:before {
  content: "\e633";
}

.hr-caozuoxitong:before {
  content: "\e65f";
}

.hr-list_hetongjiesuan:before {
  content: "\e63d";
}

.hr-qian-:before {
  content: "\e625";
}

.hr-yinhangzhanghu:before {
  content: "\e61a";
}

.hr-icon_fafangguanli_xinchoupicitijiao:before {
  content: "\e75c";
}

.hr-feiyongshenqing:before {
  content: "\e60d";
}

.hr-a-17houbufeiyongguanli:before {
  content: "\e65d";
}

.hr-record:before {
  content: "\e7c1";
}

.hr-jineshuaxin:before {
  content: "\e628";
}

.hr-wuyehetong:before {
  content: "\e6b0";
}

.hr-dkw_jiekuan:before {
  content: "\e606";
}

.hr-weidaijiekuanxinxichaxun:before {
  content: "\e668";
}

.hr-jiekuan:before {
  content: "\e6c8";
}

.hr-he_34hetongguanli:before {
  content: "\e70f";
}

.hr-a-jiekuan3:before {
  content: "\eab5";
}

.hr-gongziguanli:before {
  content: "\e6a7";
}

.hr-kaoqindaka-xian:before {
  content: "\e89a";
}

.hr-kaoqindaka:before {
  content: "\e83c";
}

.hr-dian:before {
  content: "\ec1e";
}

.hr-down:before {
  content: "\e662";
}

.hr-kaoqindaqia:before {
  content: "\e689";
}

.hr-icon-:before {
  content: "\e62e";
}

.hr-record-full:before {
  content: "\e890";
}

.hr-pay-center-salary-adjustment:before {
  content: "\e605";
}

.hr-gongshi:before {
  content: "\e67c";
}

.hr-waidihukoulizhixiaohutiqu:before {
  content: "\e608";
}

.hr-shangchuandaochu:before {
  content: "\e8c6";
}

.hr-daochu:before {
  content: "\e638";
}

.hr-caogaoxiang1:before {
  content: "\e604";
}

.hr-chakan:before {
  content: "\e603";
}

.hr-lizhirenyuan:before {
  content: "\e632";
}

.hr-chakan1:before {
  content: "\e611";
}

.hr-lizhishenqingshenpiliucheng-02:before {
  content: "\e99b";
}

.hr-kaoqin:before {
  content: "\e614";
}

.hr-flow-entry:before {
  content: "\e65c";
}

.hr-bohui-03:before {
  content: "\e66b";
}

.hr-gongren:before {
  content: "\e64f";
}

.hr-daoru:before {
  content: "\e635";
}

.hr-daoru1:before {
  content: "\e637";
}

.hr-jiangcheng:before {
  content: "\e806";
}

.hr-man-out:before {
  content: "\e69b";
}

.hr-lizhi:before {
  content: "\e6bb";
}

.hr-huamingce:before {
  content: "\e71a";
}

.hr-ruzhi:before {
  content: "\e746";
}

.hr-qiyerencaiku_hua:before {
  content: "\eaa9";
}

.hr-dengjigongshi:before {
  content: "\e610";
}

.hr-icon-import:before {
  content: "\e65b";
}

.hr-hrrenshirenshiguanli380:before {
  content: "\e67e";
}

.hr-zhaopin:before {
  content: "\e646";
}

.hr-kaoqinshenpi:before {
  content: "\e60b";
}

.hr-jisuan:before {
  content: "\e65a";
}

.hr-zuzhi:before {
  content: "\e683";
}

.hr-xiangmu:before {
  content: "\ea3f";
}

.hr-shezhi1:before {
  content: "\e618";
}

.hr-31erweima:before {
  content: "\e602";
}

.hr-shezhi:before {
  content: "\e68f";
}

.hr-empty-status:before {
  content: "\e60e";
}

.hr-shiwuzhongxin_bumenhuiyi:before {
  content: "\e6f0";
}

.hr-daohangdizhi:before {
  content: "\e65e";
}

.hr-fengxianceping01:before {
  content: "\e615";
}

.hr-fengxianguankongguanli:before {
  content: "\e62d";
}

.hr-hidden-danger_fill:before {
  content: "\e753";
}

.hr-icon_xuncharenwuyiban:before {
  content: "\e631";
}

.hr-saoma:before {
  content: "\e713";
}

.hr-shangyiyedanjiantou:before {
  content: "\e712";
}

.hr-xiashuangjiantou:before {
  content: "\e617";
}

.hr-mokuai-shang:before {
  content: "\e64d";
}

.hr-a-ziyuan56:before {
  content: "\e60f";
}

.hr-iconset0181:before {
  content: "\e650";
}

.hr-renshu:before {
  content: "\e678";
}

.hr-weixin:before {
  content: "\e601";
}

.hr-shouji:before {
  content: "\e6b7";
}

.hr-unlock:before {
  content: "\e882";
}

.hr-chahao:before {
  content: "\e669";
}

.hr-sousuo:before {
  content: "\e622";
}

.hr-gougou:before {
  content: "\e6a3";
}

.hr-handoup:before {
  content: "\e627";
}

.hr-wenben:before {
  content: "\e600";
}

.hr-shanchu:before {
  content: "\e643";
}

