.publicity-action {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.button-bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.ant-table {
  .ant-table-cell::before {
    width: 0 !important;
  }
}

@primary-color: #2ac2bd;